import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";

const mobileCSS = "@media (max-width: 768px)";

const MAX_BUDGET_POSSIBLE = 100000;
const THEORETICAL_MAX_PRICE = 500000; // Should really be open ended

const Form = (props) => {
  const {
    className,
    formValues,
    setFormValues,
    defaultValues,
    setLatestResults,
    hasSubmitted,
    setHasSubmitted
  } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSliderChange = (name) => (e, value) => {
    if (value[1] === MAX_BUDGET_POSSIBLE) {
      value[1] = THEORETICAL_MAX_PRICE;
    }
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const cleanedFormValues = {
      ...formValues,
      // dailyRange: (formValues.dailyRange || '').split(',').map(n => n ? parseInt(n) : 0)
    }

    // console.log(cleanedFormValues);
    
    // let res = { data: { body: [{
    //   _id: 1,
    //   make: 'Chevrolet',
    //   model: 'Bolt EV',
    //   priceLow: 31000,
    //   priceHigh: 34200,
    //   range: 265,
    //   edmunds: 8
    // },
    // {
    //   _id: 7,
    //   make: 'Hyundai',
    //   model: 'Ioniq 5 Limited AWD',
    //   priceLow: 39950,
    //   priceHigh: 55000,
    //   range: 270,
    //   edmunds: 8.3
    // },
    // {
    //   _id: 5,
    //   make: 'Hyundai',
    //   model: 'Kona Electric',
    //   priceLow: 34000,
    //   priceHigh: 42500,
    //   range: 315,
    //   edmunds: 8.1,
    //     rebates: [
    //       { incentives: [
    //         { evaluation: { amount_in_lease: 500 }},
    //         { evaluation: { amount_in_lease: 1500 }}
    //       ]},
    //       { incentives: [
    //         { evaluation: { amount_in_lease: 0 }},
    //         { evaluation: { amount_in_lease: 1200 }}
    //       ]},
    //     ]
    // }]}};

    // let res = { data: { body: [] } };
    

    let res;
    try {
      res = await axios.post("/submit_quiz", cleanedFormValues, { headers: { 'Content-Type': 'application/json' }}); // formerly http://loc
    } catch (error) {
      // console.log(error);
      return;
    }
    setLatestResults(res.data.body);
    setHasSubmitted(true);
  };
  return (
    <form className={className} onSubmit={handleSubmit}>
      <Grid container alignItems={"center" /* hasSubmitted ? "left" : "center" */} justifyContent="center" direction="column">
        { /* <Grid item>
          <FormLabel>How much do you drive per day?</FormLabel>
          <FormControl required={false}>
            <Select
              name="dailyRange"
              value={formValues.dailyRange}
              onChange={handleInputChange}
            >
              <MenuItem key=",50" value={[0,50].toString()}>Less than 50 miles</MenuItem>
              <MenuItem key="50,100" value={[50,100].toString()}>Between 50 and 100 miles</MenuItem>
              <MenuItem key="100," value={[100,1000].toString()}>More than 100 miles</MenuItem>
            </Select>
          </FormControl>
        </Grid> */ }
        <div className="my-8">
          <Grid item>
            <FormLabel><h6>What kind of car do you like to drive?</h6></FormLabel>
            <FormControl required={false /*{true} */} sx={{ width: '100%' }}>
              <Select
                multiple={true}
                name="bodyTypes"
                value={formValues.bodyTypes}
                onChange={handleInputChange}
              >
                <MenuItem key="sedan" value="Sedan">Sedan</MenuItem>
                <MenuItem key="SUV" value="SUV">SUV</MenuItem>
                <MenuItem key="truck" value="Truck">Truck</MenuItem>
                <MenuItem key="crossover" value="Crossover">Crossover</MenuItem>
                <MenuItem key="hatchback" value="Hatchback">Hatchback</MenuItem>
                <MenuItem key="luxury" value="Luxury">Luxury</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </div>
        <div className="mt-8 m w-full md:w-auto">
          <Grid item>
            <FormLabel><h6>What's your budget?</h6></FormLabel>
            <FormControl required={false /*{true} */} sx={{
                width: "400px",
                [mobileCSS]: { width: '100%' }
              }}>
              <div className="my-10">
                <Slider
                  value={formValues.budget}
                  valueLabelFormat={number => `${number/1000}k${number === MAX_BUDGET_POSSIBLE ? ' +' : ''}`}
                  onChange={handleSliderChange("budget")}
                  defaultValue={defaultValues.budget}
                  step={1000}
                  min={25000}
                  max={MAX_BUDGET_POSSIBLE}
                  marks={[
                    {
                      value: 25000,
                      label: "$25k",
                    },
                    {
                      value: 50000,
                      label: "$50k",
                    },
                    {
                      value: 75000,
                      label: "$75",
                    },
                    {
                      value: MAX_BUDGET_POSSIBLE,
                      label: "$100k +",
                    }
                  ]}
                  valueLabelDisplay="on"
                />
              </div>
            </FormControl>
          </Grid>
        </div>
        <div className="">
          <Grid item>
            <FormLabel><h6>Do you have a dedicated parking spot close to a power outlet?
            <Tooltip title="Charging at home is an important factor for your range requirements" placement="top-start">
              <IconButton sx={{ "m": "10px", width: "0px", height: "0px"}}><InfoOutlinedIcon /></IconButton>
            </Tooltip>
              </h6>
            </FormLabel>
            <FormControl required={false /*{true} */} sx={{ width: '100%' }}>
              <Select
                name="homeCharge"
                value={formValues.homeCharge}
                onChange={handleInputChange}
              >
                <MenuItem key="yes" value="yes">Yes</MenuItem>
                <MenuItem key="no" value="no">No</MenuItem>
                <MenuItem key="notSure" value="notSure">Not Sure</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </div>
        <div className="mt-8">
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </div>
      </Grid>
    </form>
  );
};
export default Form;
