import React, { useEffect } from 'react';
import ResultCard from './ResultCard';

const Results = (props) => {
    const { latestResults, className, id, hasSubmitted, setHasSubmitted } = props;

    // auto-scroll to results
    useEffect(() => {
        if (hasSubmitted) {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            setHasSubmitted(false);
        }
    }, [hasSubmitted]);

    const fullClassName = `container flex flex-col justify-between grow ${className}`;

    if (latestResults) {
        if (latestResults.length) {
            return (
                <div id={`${id}`} className={fullClassName}>
                    <h5 className="my-8">
                        <a href="https://www.electricforall.org/rebates-incentives/#zipcode-submit" target="_blank"  rel="noopener noreferrer">
                            See how much you can save through federal and local incentives and rebates
                        </a>
                    </h5>
                    {latestResults.map(result => (
                        <ResultCard result={result} />
                    ))}
                </div> 
            );
        } else {
            return(
                <div id={`${id}`} className={fullClassName}>
                    <h5 className="my-8">Sorry, no EVs on the market matched your preferences. Try broadening your search.</h5>
                </div>
            );
        }
    } else {
        return(
            <div id={`${id}`} className={fullClassName}>
                <h5 className="my-8">Submit to see results</h5>
            </div>
        );
    } 
}

export default Results;