import React from 'react';
import HamburgerMenu from '../../shared/HamburgerMenu';
import Footer from '../../shared/Footer';
import { Link } from "react-router-dom";

function NotFoundPage(props) {
  return (
    <div>
      <main>
        <div className="container w-4/5  text-center text-gray-800 h-[100vh] md:h-[75vh]">
          <div className="md:pt-12">
            {/* mobile */}
            <div className="md:hidden mt-16">
              <h1 className="mb-16">
                Sorry, that's not a valid page
              </h1>
              <h4>
                <Link to="/"  style={{ textDecoration: 'none', color: '#0397B7', fontWeight: 'bold' }}>
                  {"Click here "}
                </Link>
                to get back to the main site
              </h4>
            </div>
            
            {/* desktop */}
            <div className="max-md:hidden md:visible">
              <h1 className="mb-16">
                Sorry, that's not a valid page
              </h1>
              <h4>
                <Link to="/"  style={{ textDecoration: 'none', color: '#0397B7', fontWeight: 'bold' }}>
                  {"Click here "}
                </Link>
                to get back to the main site
              </h4>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default NotFoundPage;