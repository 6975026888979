import logo from './logo.svg';
import './App.css';
import LandingPage from './pages/Landing/LandingPage';
import BlogPage from './pages/blog/BlogPage';
import BlogPostPage from './pages/blog/BlogPostPage';
import AllArticlesExport  from './blogContent/AllArticlesExport';
import NotFoundPage from './pages/NotFound/NotFoundPage';
import { Route, Routes } from "react-router-dom"

const BLOG_PATH = '/blog';

function App() {
  console.log(Object.keys(AllArticlesExport));
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path={BLOG_PATH} element={<BlogPage />} />
      <Route path="*" element={<NotFoundPage />} />
      {
        Object.keys(AllArticlesExport).map(key => (
          <Route path={BLOG_PATH + '/' + key} element={<BlogPostPage {...AllArticlesExport[key]} />} key={key} />
        ))
      }
    </Routes>
  );
}

export default App;
