import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = (props) => {
  const { title, subtitle, href } = props;
  return (
    <Link to={href} style={{ textDecoration: 'none'}}>
      <div className="md:my-8 text-slate rounded-md border-black drop-shadow-lg bg-gradient-to-tr to-cyan-200 from-cyan-500 h-[250px]">
          <div className="p-4 md:px-8 text-center">
            <h3 className="my-4">
              {title}
            </h3>
            <h6 className="my-8">
              {subtitle}
            </h6>
        </div>
      </div>
    </Link>
  );
}

export default BlogCard;