import React, { useState } from 'react';
import Form from './Form';
import Results from './Results';

const defaultValues = {
    budget: [30000, 60000],
    bodyTypes: ['Crossover'],
    homeCharge: ['yes']
};

const FormLayout = () => {
    const [formValues, setFormValues] = useState(defaultValues);
    const [latestResults, setLatestResults] = useState();
    const [hasSubmitted, setHasSubmitted] = useState(false);


    return(
        <section id="main-form" className="pt-8">
            <div className="container w-4/5 text-center md:mb-48 md:w-full md:text-left">
                <div className="mb-8 md:p-0">
                    <h1>
                        Explore all electric cars
                    </h1>
                    <h5>
                        Filter by budget and car type to learn more about your options
                    </h5>
                </div>
                <div className={`grid grid-cols-1 md:grid-cols-3 md:gap-24`}>
                    <Form
                        // className="flex-1"
                        className={latestResults && latestResults.length ? "md:col-span-1" : "md:col-span-3"}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        defaultValues={defaultValues}
                        setLatestResults={setLatestResults}
                        hasSubmitted={hasSubmitted}
                        setHasSubmitted={setHasSubmitted}
                    />
                    <Results
                        id="your-results"
                        latestResults={latestResults}
                        className={(!latestResults || !latestResults.length) ? "md:col-span-3 text-center" : "md:col-span-2"}
                        hasSubmitted={hasSubmitted}
                        setHasSubmitted={setHasSubmitted}
                    />
                </div>
            </div>
        </section>
    );
}

export default FormLayout;