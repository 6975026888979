export default {
    title: "Electric cars are cheaper to drive and maintain than gas cars",
    subtitle: "November 3, 2022",
    content:`
# Electric cars are cheaper to drive and maintain than gas cars
By Tom Kremer
November 3, 2022
[Read this on Medium](https://medium.com/@goin_electric/electric-cars-are-cheaper-for-owners-compared-to-gas-car-b0c7ac2f783d)

Most gas-car drivers take running costs for granted. Things like fuel and maintenance are just part of the price of owning a car, right?
Not anymore. Electric vehicles (EVs) can save the average American driver thousands of dollars annually. In this post, I’ll break down the savings from fuel and maintenance costs.

**If you want to just see the numbers, scroll to the bottom of this post.**

## Comparing gas and charge prices
According to  [Kelley Blue Book](https://www.kbb.com/car-advice/how-much-does-it-cost-to-charge-an-ev/), the average American driver spends about $182 per month on gas, or  **$2,184 per year**. That’s calculated with the assumption of a car that gets 30 miles per gallon and using the national average price in late August 2022, which was $3.88 per gallon  [according to AAA](https://gasprices.aaa.com/state-gas-price-averages/).
By comparison, the average American driver uses just  [$59 a month](https://www.kbb.com/car-advice/how-much-does-it-cost-to-charge-an-ev/)  worth of electricity, or  **$708 per year**, using the U.S. household average from June 2022 of about 15 cents per kWh.
On top of that, many workplaces and public charging stations will charge EVs for free, meaning that many EV drivers pay even less than that. Anecdotally, my brother paid $14 last month, since his workplace supplied over 60% of his charge at no cost.

![A screenshot of my brother’s charge stats over the past 31 days. Because 62% of his charging was free at his workspace, he only spent $14.](https://miro.medium.com/max/1400/1*SlUrEogSJ1mHSHupaBHglA.png)

A screenshot of my brother’s charge stats over the past 31 days (mostly October 2022)

**Total annual savings: $1,476**

## Comparing maintenance costs

Oil changes, brake pads, tires — they don’t last forever, and they require proper maintenance and replacements to safely drive a car with an internal combustion engine (ICE).
The average American spends  **$1,278 per year**  in maintenance costs for their ICE vehicles when using  [AAA’s estimate](https://www.aaa.com/autorepair/articles/what-does-it-cost-to-own-and-operate-a-car)  of $0.09 per mile and the national average 14,200 miles driven per year.
EVs, on the other hand, typically require less maintenance than conventional vehicles, according to the  [Alternative Fuels Data Center](https://afdc.energy.gov/vehicles/electric_maintenance.html). That’s because
-   The battery, motor, and electronics require little to no regular maintenance
-   There are fewer fluids (for example, engine oil) to replace
-   Brake wear is significantly reduced because of regenerative braking

According to  [GreenCars](https://www.greencars.com/expert-insights/cost-to-maintain-an-electric-car), EVs cost on average  **$330 less per year**  than their gas counterparts, if maintained according to the manufacturer’s recommendations.

**Total annual savings: $330**

## The Bottom Line

It’s significantly cheaper to own and use an electric car compared to a gas car. There are many factors to consider that are unique to each vehicle type and location, so it’s important to  **do your own research**  before buying.
For the average US driver, switching to an electric car can save them:
-   **$1,476 per year in fuel costs**
-   **$330 per year in maintenance costs**

for a grand total of  **$1,806 saved** per year.
If you’re thinking about buying an EV, check out my website  [GoinElectric](https://www.goinelectric.com/)  to find the right one for you.\

    `
}
