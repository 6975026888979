import React from 'react';
import { Remarkable } from 'remarkable';
var md = new Remarkable();

function BlogPost (props) {
    const { title, subtitle, content, writtenDate, author } = props;

    const Article = (props) => {
        const { text } = props;
        const groomedText = text.replace(/\n\r/, '\n');
        // const lines = text.split(/\r?\n/);
        // console.log(lines.length);
        const convertedMarkdown = md.render(groomedText); // lines.map(line => (md.render(line))).join("\n");
        console.log(convertedMarkdown);
        return (
            <div dangerouslySetInnerHTML={{__html:convertedMarkdown}} />
        )
    }

    return (
        <div className="display-linebreak text-left">
            <Article text={content} />
        </div>
    )
}

export default BlogPost;
