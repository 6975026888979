// {
//     _id: 1,
//     make: 'Chevrolet',
//     model: 'Bolt EV',
//     priceLow: 31000,
//     priceHigh: 34200,
//     range: 265,
//     edmunds: 8
//   }

const addCommas = number => (
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
);

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});  
  
const ResultCard = (props) => {
    // console.log(formatter.format(123234))
    const {
        result: {
            make,
            model,
            priceLow,
            priceHigh,
            range,
            edmunds,
            edmundsUrl,
            imgUrl,
            rebates = []
        }
    } = props;

    // Assuming lease and purchase have the same savings!
    const incentivesTotal = rebates?.reduce(
        (acc, r) => Math.max(
            acc,
            r?.incentives?.reduce(
                (acc, i) => acc + (i?.evaluation?.amount_in_lease || 0), 0
            )
            ),
            0); 

    const salesUrl = `https://www.edmunds.com/inventory/srp.html?inventorytype=new&make=${make.toLowerCase()}&model=${model.replace(' ', '-').toLowerCase()}`;

    return (
        <div className="p-4 px-8 justify-between grid md:grid-cols-2 gap-8 mb-12 bg-cyan-main text-white rounded-md drop-shadow-lg bg-gradient-to-tr from-transparent to-cyan-600">
            <div className="text-left">
                <h3 className="my-4">
                    {make}
                    {" "}
                    {model}
                </h3>
                <h6 className="font-semibold">
                    {`$${addCommas(priceLow)}${priceHigh ? ` - $${addCommas(priceHigh)}` : ''}`}
                </h6>
                <h6 className="font-semibold">
                    Total rebates: {formatter.format(incentivesTotal || 0)}{" "}
                    <span>
                        <a href="https://www.electricforall.org/rebates-incentives/#zipcode-submit" target="_blank" rel="noopener noreferrer">
                            (Learn more)
                        </a>
                    </span>
                </h6>
                <h6>
                    <span className="font-semibold">
                        {`Range: ${range} miles `}
                    </span>
                    (tested by Edmunds)
                </h6>
                <h6 className="font-semibold">
                    {`Edmunds Score: ${edmunds}`}
                    <br />
                </h6>
                <h6>
                    <a href={edmundsUrl} target="_blank">See review</a>
                </h6>
                <h6>
                    <a href={salesUrl} target="_blank">Find one for sale near me</a>
                </h6>
            </div>
            <img className="align-right" src={imgUrl} alt={`${make} ${model}`} width="100%" height="100%" />
        </div>
    )
};

export default ResultCard;