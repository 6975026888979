import React from 'react';
import Page from '../Page'
import AllPosts from '../../blogContent/AllPosts';


function BlogPage(props) {
  return (
    <Page title="Blog">
      <AllPosts />
    </Page>
  );
}

export default BlogPage;