import React from 'react';
import CardList from './CardList';
import HamburgerMenu from '../../shared/HamburgerMenu';
import Footer from '../../shared/Footer';
import FormLayout from '../Quiz/FormLayout';
import { Link } from 'react-router-dom';

function LandingPage(props) {
  return (
    <React.Fragment>
      <main>
        <div className="bg-[url('images/TeslaInWoods.jpg')] h-[100vh] bg-bottom md:bg-top bg-cover flex flex-col" id="top">
        <HamburgerMenu/>

          <span role="img" aria-label='Photo by <a href="https://unsplash.com/@adityachinchure?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Aditya Chinchure</a> on <a href="https://unsplash.com/s/photos/scenic-EV?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>">'/>
          <div className="container w-4/5  text-center text-gray-200 h-[100vh] md:h-[75vh]">
            <Link to="/" style={{ textDecoration: 'none'}}>
              <div className="pr-16 md:pr-0 pt-8 w-full text-right">
                <h3 className="font-extrabold italic">Goin' Electric</h3>
              </div>
            </Link>
            <div className="pt-12 md:pt-48">
              {/* mobile */}
              <div className="md:hidden mt-16">
                <h1 className="mb-16">
                  It's easier than ever to find the right <span className="text-cyan-main">electric</span> car
                </h1>
                <a href="#main-form">
                  <button>
                    <h4>Let's go electric</h4>
                  </button>
                </a>
              </div>
              
              {/* desktop */}
              <div className="max-md:hidden md:visible">
                <h1>
                  It's easier than ever to find the right <span className="text-cyan-main">electric</span> car
                </h1>
                <a href="#main-form">
                  <button>
                    <h4>Let's go electric</h4>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <CardList />
        <FormLayout />
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default LandingPage;