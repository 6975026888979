const Footer = () => (
    <footer className="mt-24 mb-8 text-center">
        <h6>
            Want to chat? Email me:
        </h6>
        <h6>
            tom at goin electric dot com
        </h6>
  </footer>
)

export default Footer;