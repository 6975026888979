import React from 'react';

const cards = [{
    title:  "All EV models",
    subtitle: "Compare across every EV you can own or lease in the US"
  }, {
    title: "Charging networks",
    subtitle: "Filter by your area's charging station types and availability"
  }, {
    title: "Government incentives",
    subtitle: "Automatically apply federal and local rebates and subsidies",
  }, {
    title: "Fuel savings",
    subtitle: "See your annual savings if you switch from gas to the grid"
  }, {
    title: "Low maintenance",
    subtitle: "See how much you save with reduced maintenance needs"
  }, {
    title: "Safer to drive",
    subtitle: "Compare safetry ratings to make the best informed decision"
  }
];

const CardList = () => (
  <div className="container my-48 w-4/5 md:w-full">
    <div className="mb-12 text-center md:text-left">
      <h1>
        Looking for an electric car?
      </h1>
      <h5>
        We did the research - you do the fun parts
      </h5>
    </div>
    <div className="grid gap-8 md:grid-cols-3">
      {cards.map(card => (
        <div key={card.title} className="md:my-8 bg-cyan-main text-white rounded-md drop-shadow-lg bg-gradient-to-tr from-transparent to-cyan-600">
            <div className="p-4 md:px-8 text-center">
              <h3 className="my-4">
                {card.title}
              </h3>
              <h6 className="my-8">
                {card.subtitle}
              </h6>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default CardList;