import React from 'react';
import Page from '../Page'
import BlogPost from '../../blogContent/BlogPost';

function BlogPosts (props) {
    return (
        <Page>
            <BlogPost {...props} />
        </Page>
    );
}

export default BlogPosts;