import React from 'react';
import HamburgerMenu from '../shared/HamburgerMenu';
import Footer from '../shared/Footer';
import { Link } from 'react-router-dom';

function Page(props) {
    const { title, children } = props;
  return (
    <div>
      <main>
        <HamburgerMenu/>

        <div className="container w-4/5  text-center text-gray-800">
            <Link to="/" style={{ textDecoration: 'none'}}>
                <div className="pr-16 md:pr-0 pt-8 w-full text-right">
                    <h3 className="font-extrabold italic">Goin' Electric</h3>
                </div>
            </Link>
          <div className="md:pt-12">
            {/* mobile */}
            <div className="md:hidden mt-16">
              <h1 className="mb-16">
                {title}
              </h1>
            </div>
            
            {/* desktop */}
            <div className="max-md:hidden md:visible">
              <h1 className="mb-16">
                {title}
              </h1>
            </div>
          </div>
          { children }
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Page;