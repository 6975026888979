import AllArticlesExport from './AllArticlesExport';
import BlogCard from "./BlogCard";

function AllPosts () {
    return (
        <div className="container mb-48 w-4/5 md:w-full">
            <div className="grid gap-8 md:grid-cols-2">
            { /* Matches routing logic in App.js */}
            { Object.entries(AllArticlesExport).map(([key, { title, subtitle }]) => (
                <BlogCard
                    title={title}
                    subtitle={subtitle}
                    href={key}
                    key={key}
                />
                ))}
            </div>
        </div>
    );
}

export default AllPosts;