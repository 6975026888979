import { slide as Menu } from 'react-burger-menu'
import React, { useState } from "react";
import { Link } from 'react-router-dom';

function HamburgerMenu(props) {

    var styles = {
        bmBurgerButton: {
          position: 'fixed',
          width: '36px',
          height: '30px',
          right: '36px',
          top: '36px'
        },
        bmBurgerBars: {
          background: '#ddd'
        },
        bmBurgerBarsHover: {
          background: '#a90000'
        },
        bmCrossButton: {
          height: '36px',
          width: '36px'
        },
        bmCross: {
          background: 'black',
          height: '24px'
        },
        bmMenuWrap: {
          position: 'fixed',
          height: 'auto'
        },
        bmMenu: {
          background: '#ccc',
          padding: '2.5em 1.5em 1em',
          fontSize: '1.15em',
          height: 'auto',
          borderRadius: '2'
        },
        bmMorphShape: {
          fill: '#373a47'
        },
        bmItemList: {
          color: '#b8b7ad',
          padding: '0.8em'
        },
        bmItem: {
            textDecoration: 'none'
        },
        bmOverlay: {
          background: 'rgba(0, 0, 0, 0.3)'
        }
    }

    const [isMenuOpen, setIsMenuOpen] = useState();

    const handleLinkClick = e => {
        setIsMenuOpen(!isMenuOpen);
    }

    const closeMenu = e => {
        setIsMenuOpen(false);
    }

    const MenuItemText = (props) => (
      <div onClick={closeMenu} className="menu-item">
          {
            props.header ? (
              <h3 className="mt-4 mb-8 text-black italic font-extrabold">
                {props.children}
              </h3>
            ) : (
              <h4>
                {props.children}
              </h4>
            )
        }
      </div>
    );
    
    const MenuItem = (props) => (
        <div className="my-2">
          {
            props.href ? (
              <a href={props.href} style={{ textDecoration: 'none', color: '#0397B7', fontWeight: 'bold' }}>
                <MenuItemText {...props} />
              </a>
            ) : (
              <Link to={props.to} style={{ textDecoration: 'none', color: '#0397B7', fontWeight: 'bold' }}>
                <MenuItemText {...props} />
              </Link>
            )
          }
        </div>
    );

    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
        <Menu styles={styles} right isOpen={isMenuOpen} onOpen={handleLinkClick} onClose={handleLinkClick}>
            <MenuItem href="/#top" header={true}>
                    Goin' Electric
            </MenuItem>
            <MenuItem href="/#top">Home</MenuItem>
            <MenuItem href="/#main-form">Find an EV</MenuItem>
            <MenuItem to="/blog">Learn more</MenuItem>
            {/* <a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a> */}
        </Menu>
    );
}

export default HamburgerMenu;
